import request from '@/utils/request'
const base = '/crops_info_api'
// 获取openID
export function cropTypeList(params) {
	return request({
		url: `${base}/f/query_list`,
		method: 'post',
		params,
    data:{}
	})
}

//小程序-根据主键查询
export function cropById(params) {
	return request({
		url: `${base}/f/load_by_id`,
		method: 'post',	
		params,
		data:{}
	})
}