import Vue from 'vue'
import VueRouter from 'vue-router'

import vueLayouts from '@/layout/vueLayouts.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    component: () => import('@/views/login.vue'),
    hidden: true
  },
  {
    path:'/register',
    name:'register',
    component: () => import('@/views/register.vue'),
  },
  {
    path: '/',     
    component: vueLayouts,
    meta:{
      title:'首页'
    },
    redirect: '/home',
    children:[
      {
        path:'/home',
        name:'home',
        component: () => import('@/views/home.vue'),
      },
      {
        path:'/informationDetail',
        name:'informationDetail',
        component: () => import('@/views/information/detail.vue'),
      },
      {
        path:'/information',
        name:'information',
        component: () => import('@/views/information/index.vue'),
      },
      {
        path:'/technologyDetail',
        name:'technologyDetail',
        component: () => import('@/views/technology/detail.vue'),
      },
      {
        path:'/technology',
        name:'technology',
        component: () => import('@/views/technology/index.vue'),
      },
      {
        path:'/export',
        name:'export',
        component: () => import('@/views/export/index.vue'),
      },
      {
        path:'/exportDetail',
        name:'exportDetail',
        component: () => import('@/views/export/detail.vue'),
      },
      {
        path:'/chatroom',
        name:'chatroom',
        component: () => import('@/views/export/chatroom.vue'),
      },
      {
        path:'/crop',
        name:'crop',
        component: () => import('@/views/crop/index.vue'),
      },
      {
        path:'/cropDetail',
        name:'cropDetail',
        component: () => import('@/views/crop/detail.vue'),
      },
      {
        path:'/course',
        name:'course',
        component: () => import('@/views/course/index.vue'),
      },
      {
        path:'/courseDetail',
        name:'courseDetail',
        component: () => import('@/views/course/detail.vue'),
      },
      {
        path:'/userCenter',
        name:'userCenter',
        component: () => import('@/views/userCenter/index.vue'),
      },
      
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
