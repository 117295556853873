import request from '@/utils/request'
const base = '/home_api'
// 获取openID
export function homeBanner(data,params) {
	return request({
		url: `${base}/f/home_banner`,
		method: 'post',
		params,
    data
	})
}
// 小程序-作物专栏
export function homeBreed(data,params) {
	return request({
		url: `${base}/f/home_breed`,
		method: 'post',
		params,
    data
	})
}

// 小程序-专家专栏
export function homeexpert(data,params) {
	return request({
		url: `${base}/f/home_expert`,
		method: 'post',
		params,
    data
	})
}
// 小程序-专家专栏
export function homeinformation(data,params) {
	return request({
		url: `${base}/f/home_information`,
		method: 'post',
		params,
    data
	})
}

// 小程序-专家专栏
export function homecourse(data,params) {
	return request({
		url: `${base}/f/home_courseInfo`,
		method: 'post',
		params,
    data
	})
}



