import Vue from 'vue'
import App from './App.vue'
import Element from 'element-ui'
import router from './router'
import store from './store'
import './assets/styles/element-variables.scss'
import Cookies from 'js-cookie'
import '@/assets/styles/index.scss' // global css
import '@/assets/styles/ruoyi.scss' // ruoyi css
import VIcon from '@/components/VIcon'// svg component
Vue.config.productionTip = false
Vue.use(Element, {
  size: Cookies.get('size') || 'medium' // set element-ui default size
})
Vue.component('VIcon', VIcon)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
