<template>
  <svg
    aria-hidden="true"
    :class="clazz"
    :style="style"    
    @click="handClick"
  >
    <use :xlink:href="xlinkHref" />
  </svg>
</template>

<script>
// 导入svg图标库
import '@/assets/iconfont.js'

export default {
  name: 'VIcon',
  props: {    
    size: {
      type: [Number, String],
      default: 14
    },
    name: {
      type: String,
      required: true
    },
    scale: {
      type: [Number, String],
      default: 0
    },
    spin: Boolean
  },
  computed: {
    xlinkHref () {
      return `#icon-${this.name}`
    },
    style () {
      let fontSize = this.scale ? this.scale + 'em' : this.size + 'px'
      return { fontSize }
    },
    clazz () {
      return {
        icon: true,
        spin: this.spin
      }
    }
  },
  methods: {
    handClick (event) {
      this.$emit('click', event)
    }
  }
}
</script>

<style scoped>
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.25em;
  fill: currentColor;
  overflow: hidden;
}

.spin {
  animation: spin 1s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
