import request from '@/utils/request'
let base  = '/login_api'

// 获取openID
export function login(params) {
	return request({
		url: `${base}/f/login`,
		method: 'post',
		params,
		isToken:false
	})
}



// 获取用户详细信息
export function getInfo() {
  return request({
    url: `${base}/current`,
    method: 'post'
  })
}

// 获取用户详细信息
export function register(params) {
  return request({
    url: `${base}/f/sms_register_login`,
    method: 'post',
		params
  })
}
