<template>
  <div class="page">
    <div class="header">
      <div class="topup">
        <div style="width: 1200px;margin: 0 auto;display: flex;">
          <div>欢迎来到生机田园！！！</div>
         
        </div>
      </div>
      <div class="navver">
        <div class="nav w1200">
          <!-- <div class="logo">
          <div class="fl mt20" style="color: #15af5d;font-size: 30px;font-weight: 600;">
            生机田园网
          </div>
        </div> -->
          <ul class="ul" v-if="key != 'userCenter'">
            <li class="li"
              style="color: #15af5d;font-size: 30px;font-weight: 600;width: 150px;height: 50px;margin-right: 80px;margin-top: 0;text-align: left;">
              <img src="@/assets/logo1.png" style="width: 150px;"/>
              <!-- 生机田园网 -->
            </li>
            <li class="li" :class="key.indexOf('/home') != -1 ? 'on' : ''" @click="tohome('/home')">首页
            </li>
            <li class="li" :class="key.indexOf('/information') != -1 ? 'on' : ''" @mouseenter="pageMouseEnter($event)"
              @mouseleave="pageMouseLeave($event)">
              行业资讯
              <!-- <ul class="dorpdownmenu"> -->
              <ul class="dorpdownmenu" v-show="isChildrenVisible">
                <li v-for="item in dictList" :key="item.optionCode" class="item"
                  @click="tohome('/information?' + `optionCode=${item.optionCode}`)">{{ item.optionName }}</li>
              </ul>
            </li>
            <li class="li" :class="key.indexOf('/export') != -1 ? 'on' : ''" @mouseenter="expertMouseEnter($event)"
              @mouseleave="expertMouseLeave($event)">
              专家风采
              <!-- <ul class="dorpdownmenu"> -->
              <ul class="dorpdownmenu" v-show="isChildrenexpertVisible">
                <li v-for="item in categoryList" :key="item.id" class="item"
                  @click="tohome('/export?' + `id=${item.id}`)">
                  {{
                  item.typeName }}</li>
              </ul>
            </li>
            <li class="li" :class="key.indexOf('/technology') != -1 ? 'on' : ''" @mouseenter="techMouseEnter($event)"
              @mouseleave="techMouseLeave($event)">
              技术文章
              <!-- <ul class="dorpdownmenu"> -->
              <ul class="dorpdownmenu" v-show="isChildrentechVisible">
                <li v-for="item in dictList2" :key="item.optionCode" class="item"
                  @click="tohome('/technology?' + `optionCode=${item.optionCode}`)">{{ item.optionName }}</li>
              </ul>
            </li>
            <li class="li" :class="key.indexOf('/crop') != -1 ? 'on' : ''" @mouseenter="cropMouseEnter($event)"
              @mouseleave="cropMouseLeave($event)">
              农业作物
              <!-- <ul class="dorpdownmenu"> -->
              <ul class="dorpdownmenu" v-show="isChildrencropVisible">
                <li v-for="item in categoryList" :key="item.optionCode" class="item"
                  @click="tohome('/crop?' + `id=${item.id}`)">
                  {{ item.typeName }}</li>
              </ul>
            </li>
            <!-- <li class="li" :class="key.indexOf('/course') != -1 ? 'on' : ''"  @mouseenter="courseMouseEnter($event)" @mouseleave="courseMouseLeave($event)"> -->
            <li class="li" :class="key.indexOf('/course') != -1 ? 'on' : ''" @click="tohome('/course')">
              专业讲堂
              <!-- <ul class="dorpdownmenu"> -->
              <ul class="dorpdownmenu" v-show="isChildrencourseVisible">
                <li v-for="item in categoryList" :key="item.optionCode" class="item"
                  @click="tohome('/course?' + `id=${item.id}`)">{{ item.typeName }}</li>
              </ul>
            </li>


          </ul>
          <div class="right" style="margin-top: 20px;margin-left: auto;">
            <el-dropdown class="avatar-container right-menu-item hover-effect" trigger="click">
              <div class="avatar-wrapper">

                <img :src="avatar" style="width: 30px;height: 30px;border-radius: 50%;margin-top: 5px;" />
              </div>
              <template v-slot:dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item @click.native="tohome('/userCenter')">个人中心</el-dropdown-item>
                  <el-dropdown-item divided @click.native="logout">
                    <span>退出登录</span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
        </div>
      </div>
      <div class="banner w1200">
        <el-carousel indicator-position="outside" height="450px">
          <el-carousel-item v-for="(item, index) in banner_list" :key="index">
            <img :src="item.pc_banner_img">
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>

    <appMine class="content"></appMine>
    <div class="w1920" style="background: #15af5d;">
      <div class="w1200" style="text-align: center;    margin: 0px auto;height: 140px;">
        <div style="clear:both; height:20px;color: white;"></div>
        <div class="footer_menu_ny">
          <a>网站首页</a> |
          <a>关于我们</a> &nbsp;|&nbsp;
          <a>联系方式</a> &nbsp;|&nbsp;
          <a>使用协议</a> &nbsp;|&nbsp;
          <a>版权隐私</a> &nbsp;|&nbsp;
          <a>广告服务</a>
        </div>
        <div style="clear:both;"></div>
        <div class="footer_txt_ny" style="color: white;">
          <br><a>湘ICP备2023004095号</a>
        </div>
      </div>
      <div class="cl"></div>
    </div>
  </div>
</template>
<script>
import { homeBanner } from '@/api/home'
import { dictbyCode } from '@/api/dict'
import { cropTypeList } from '@/api/crop'
import appMine from './components/appMine.vue'
export default {
  name: 'TianyuanWeb4Home',
  components: {
    appMine
  },
  data () {
    return {
      banner_list: [],
      naviIndex: 0,
      avatar: '',
      dictList: [],
      dictList2: [],
      isChildrenVisible: false,
      isChildrenexpertVisible: false,
      isChildrentechVisible: false,
      isChildrencropVisible: false,
      isChildrencourseVisible: false,
      menulist: [
        {
          name: '首页',
          path: '/home'
        },
        {
          name: '行业资讯',
          path: '/information'
        },
        {
          name: '专家风采',
          path: '/export'
        },
        {
          name: '技术文章',
          path: '/technology'
        },
        {
          name: '农业作物',
          path: '/crop'
        },
        {
          name: '专业讲堂',
          path: '/course'
        },
      ]
    }
  },

  computed: {
    key () {
      return this.$route.path
    }
  },
  mounted () {
    this.gethomeBanner()
    this.avatar = this.$store.getters.avatar || require("@/assets/images/u55.png")
    let that = this
    dictbyCode({ dictTypeId: 1 }).then(res => {
      that.dictList = [{ optionName: "全部", optionCode: '' }, ...res.result]
      console.log(this.dictList)
    })
    dictbyCode({ dictTypeId: 2 }).then(res => {
      that.dictList2 = [{ optionName: "全部", optionCode: '' }, ...res.result]
      console.log(this.dictList)
    })
    cropTypeList({ pageNum: 1, pageSize: 999 }).then(res => {
      // this.categoryList = [...res.result.list]
      this.categoryList = [{ typeName: "全部", id: '' }, ...res.result.list]

    })

  },

  methods: {
    pageMouseEnter (e) {
      this.isChildrenVisible = true
    },
    pageMouseLeave (e) {
      this.isChildrenVisible = false
    },
    expertMouseEnter (e) {
      this.isChildrenexpertVisible = true
    },
    expertMouseLeave (e) {
      this.isChildrenexpertVisible = false
    },
    techMouseEnter (e) {
      this.isChildrentechVisible = true
    },
    techMouseLeave (e) {
      this.isChildrentechVisible = false
    },
    cropMouseEnter (e) {
      this.isChildrencropVisible = true
    },
    cropMouseLeave (e) {
      this.isChildrencropVisible = false
    },
    // courseMouseEnter (e) {
    //   this.isChildrencourseVisible = true
    // },
    // courseMouseLeave (e) {
    //   this.isChildrencourseVisible = false
    // },
    gethomeBanner () {
      let params = {
        pageNum: 1,
        pageSize: 4
      }
      let body = {
      }
      homeBanner(body, params).then(res => {
        this.banner_list = res.result
        console.log(this.banner_list)
      })
    },

    tohome (url) {
      if (this.key !== url) {
        this.$router.push(url)
      }

    },
    logout () {
      this.$store.dispatch("LogOut").then(() => {
        console.log('LogOut')
        this.$router.push(`/login`)
      })
    }
  },
};




</script>


<style scoped lang="scss">
.page {
  width: 100%;
  height: 100%;

  .header {
    width: 100%;
    background: none;


    .topup {
      width: 100%;
      color: #333;
      height: 40px;
      line-height: 40px;
      margin: 0px auto;

      background: #f6f6f6;
      border: 1px solid #e3e3e3;

      .right {
        margin-left: auto;
      }

    }


    .navver {
      background-color: #1c8450;
      position: inherit;
      top: 0;
      left: 0;
      right: 0;
      z-index: 999;
      padding: 15px 0 10px;
      background: linear-gradient(to bottom, rgba(28, 132, 80, 1) 0%, rgba(28, 132, 80, 0) 100%);
      background: rgba(28, 132, 80, .75);
      transition: all 0.3s ease-out 0s;
      background: linear-gradient(rgba(28, 132, 80, 1), rgba(28, 132, 80, 0.8));
      transition: all .5s linear 0.4s;

      .nav {
       

        height: 90px;
        margin: 0 auto;
        display: flex;
        // margin-bottom: 20px;

        .logo {
          width: 100%;
          height: 100px;
          margin: 0 auto;
        }

        .ul {
          display: flex;
          list-style: none;
          margin: 0;
          padding: 0;
          margin-top: 20px;

          .li {
            width: 120px;
            float: left;
            text-align: center;
            height: 50px;
            font-size: 18px;
            line-height: 50px;
            position: relative;
            color: white;
            cursor: pointer;

            // margin-top: 20px;
            .dorpdownmenu {
              position: absolute;
              width: 120px;
              top: 50px;
              border: 1px solid #eee;
              left: 0;
              // display: none;
              z-index: 18;

              .item {
                height: 40px;
                line-height: 40px;
                width: 100%;
                background: white;
                color: black;
                list-style: none;
                font-size: 14px;
              }

              .item:hover {
                background-color: #1c8450;
                color: white;
              }
            }

            &.on {
              border-bottom: 2px solid white;
              // background: #15af5d;
              // color: #FFF;
              // border-radius: 30px;
            }
          }

        }
      }
    }

    .banner {
     
      height: 480px;
      // background: #F7F8FC;
      margin: 10px auto;

      .el-carousel__item {
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 450px;
          margin: 0;
        }
      }
    }



  }

  .footer_menu_ny {
    width: 1200px;
    margin: auto;
    color: #ffffff;
    height: 54px;
    text-align: center;
    font-size: 14px;
    line-height: 54px;
  }

  .content {
    height: auto;
    width: 100%;
    background: #F7F8FC;
  }
}
</style>
