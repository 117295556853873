<template>
  <section>
    <!-- -->
    <router-view :key="key"></router-view>
  </section>
</template>

<script >

export default {
  name: 'TianyuanWeb4Home',
  computed: {
    
    key() {
      return this.$route.path
    }
  },
  data () {
    return {
    
    }
  },

  mounted () {
    console.log(this.$router)
  },

  methods: {
   
  },
};
</script>
