

import request from '@/utils/request'
const base = '/dict_option_api'
// 获取openID
export function dictbyCode(data) {
	return request({
		url: `${base}/f/load_all_by_entity`,
		method: 'post',
    data
	})
}